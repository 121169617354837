import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Input, MenuItem, TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import format from "date-fns/format";
import AuthenticatedNavbar from "./../authenticatedNavbar/AuthenticatedNavbar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { backendHost } from "../../config";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ReactHookFormSelect from "../ReactHookFormSelect";
import { TableContainer } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import "./../global.css";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function (error) {
      reject(error);
    };
  });
}

const projectSchema = z.object({
  created_by: z.number().positive(),
  id: z.any(),
  name: z.string().min(1),
  customer_name: z.string().min(1),
  contact_person: z.string(),
  start_date: z.string().min(1),
  end_date: z.string().min(1),
  status: z.string().min(1),
  fax: z.number().nullish(),
  email: z.string().email().optional().or(z.literal("")),
  street: z.string(),
  city: z.string(),
  zip: z.number().nullish(),
  activity: z.string(),
  activity_status: z.string(),
  activity_description: z.string(),
  timing: z.string(),
  avatar: z.unknown().refine((val) => {
    if (!(val instanceof FileList || typeof val === "string")) {
      return false;
    }
    return true;
  }),
});

const Projects = () => {
  const queryClient = useQueryClient();
  const [openedProject, setOpenedProject] = useState(null);
  const [createOrEdit, setCreateOrEdit] = useState(null);

  const projects = useQuery({
    queryKey: "projects",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/projects/v1/projects`);
      return res?.data?.data?.projects;
    },
  });

  const employees = useQuery({
    queryKey: "employees",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/employees/v1/employees`);
      return res?.data?.data?.employees;
    },
  });

  const createProject = useMutation({
    mutationFn: async (data) => {
      return await axios.post(
        `${backendHost}/api/projects/v1/create-app`,
        data
      );
    },
  });

  const updateProject = useMutation({
    mutationFn: async (data) => {
      return await axios.post(
        `${backendHost}/api/projects/v1/edit-project/${data?.id}`,
        data
      );
    },
  });

  const defaultFormValues = {
    name: createOrEdit?.data?.name ?? "",
    customer_name: createOrEdit?.data?.customer_name ?? "",
    contact_person: createOrEdit?.data?.contact_person ?? "",
    start_date: createOrEdit?.data?.start_date
      ? format(new Date(createOrEdit?.data?.start_date), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    end_date: createOrEdit?.data?.end_date
      ? format(new Date(createOrEdit?.data?.end_date), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    status: createOrEdit?.data?.status ?? "Created",
    fax: createOrEdit?.data?.fax ?? null,
    email: createOrEdit?.data?.email ?? "",
    street: createOrEdit?.data?.street ?? "",
    city: createOrEdit?.data?.city ?? "",
    zip: createOrEdit?.data?.zip ?? null,
    activity: createOrEdit?.data?.activity ?? "",
    activity_status: createOrEdit?.data?.activity_status ?? "",
    activity_description: createOrEdit?.data?.activity_description ?? "",
    timing: createOrEdit?.data?.timing ?? "",
    avatar: createOrEdit?.data?.avatar ?? null,
    id: createOrEdit?.data?.id ?? null,
    created_by: createOrEdit?.data?.created_by ?? null,
  };

  const form = useForm({
    resolver: zodResolver(projectSchema),
    defaultValues: defaultFormValues,
  });

  const avatar = form.watch("avatar");

  const onSubmit = async (data) => {
    if (createOrEdit?.mode === "create") {
      if (data?.avatar?.[0] === null || data?.avatar?.[0] === undefined) return;
      const avatar = await getBase64(data?.avatar?.[0]);
      createProject.mutate(
        {
          name: data?.name,
          customer_name: data?.customer_name,
          contact_person: data?.contact_person,
          start_date: data?.start_date,
          end_date: data?.end_date,
          status: data?.status,
          fax: data?.fax,
          email: data?.email,
          street: data?.street,
          city: data?.city,
          zip: data?.zip,
          activity: data?.activity,
          activity_status: data?.activity_status,
          activity_description: data?.activity_description,
          timing: data?.timing,
          avatar: avatar,
          created_by: data?.created_by,
        },
        {
          onSettled: () => {
            queryClient.refetchQueries(["projects"]);
            setCreateOrEdit(null);
          },
        }
      );
    } else {
      updateProject.mutate(
        {
          name: data?.name,
          customer_name: data?.customer_name,
          contact_person: data?.contact_person,
          start_date: data?.start_date,
          end_date: data?.end_date,
          status: data?.status,
          fax: data?.fax,
          email: data?.email,
          street: data?.street,
          city: data?.city,
          zip: data?.zip,
          activity: data?.activity,
          activity_status: data?.activity_status,
          activity_description: data?.activity_description,
          timing: data?.timing,
          avatar:
            typeof data?.avatar === "string"
              ? data?.avatar
              : await getBase64(data?.avatar?.[0]),
          id: data?.id,
          created_by: data?.created_by,
        },
        {
          onSettled: () => {
            queryClient.refetchQueries(["projects"]);
            setCreateOrEdit(null);
          },
        }
      );
    }
  };

  useEffect(() => {
    form.reset(defaultFormValues);
  }, [createOrEdit?.show]);

  return (
    <>
      <AuthenticatedNavbar />
      <div className="container mx-auto space-y-4 md:pt-10 pb-[70px] pt-4 px-2">
        {/* View Modal */}
        <Dialog
          open={!!openedProject?.show}
          PaperProps={{
            style: {
              borderRadius: 20,
              margin: 0,
            },
          }}
        >
          <DialogTitle style={{ fontWeight: "bold" }}>
            {openedProject?.project?.name}
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Project Name
                  </TableCell>
                  <TableCell>{openedProject?.project?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Customer Name
                  </TableCell>
                  <TableCell>{openedProject?.project?.customer_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Contact Person
                  </TableCell>
                  <TableCell>
                    {openedProject?.project?.contact_person}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Start Date
                  </TableCell>
                  <TableCell>{openedProject?.project?.start_date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>End Date</TableCell>
                  <TableCell>{openedProject?.project?.end_date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                  <TableCell>{openedProject?.project?.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Fax</TableCell>
                  <TableCell>{openedProject?.project?.fax}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                  <TableCell>{openedProject?.project?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Street</TableCell>
                  <TableCell>{openedProject?.project?.street}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
                  <TableCell>{openedProject?.project?.city}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Zip</TableCell>
                  <TableCell>{openedProject?.project?.zip}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Activity</TableCell>
                  <TableCell>{openedProject?.project?.activity}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Activity Status
                  </TableCell>
                  <TableCell>
                    {openedProject?.project?.activity_status}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Activity Description
                  </TableCell>
                  <TableCell>
                    {openedProject?.project?.activity_description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Avatar</TableCell>
                  <TableCell>
                    <img
                      src={openedProject?.project?.avatar}
                      width={120}
                      height={120}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <button
              className="edit-btn"
              color="secondary"
              onClick={() => {
                setOpenedProject(null);
              }}
            >
              Close
            </button>
          </DialogActions>
        </Dialog>

        {/* Create/Update Modal */}
        <Dialog
          open={!!createOrEdit?.show}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: 20,
              margin: 0,
            },
          }}
        >
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogTitle>
              {createOrEdit?.mode === "create" ? "Create" : "Edit"} Project
            </DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                variant="outlined"
                label="Project Name"
                fullWidth
                {...form.register("name")}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Customer Name"
                fullWidth
                {...form.register("customer_name")}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Contact Person"
                fullWidth
                {...form.register("contact_person")}
              />
              <TextField
                margin="dense"
                variant="outlined"
                type="date"
                label="Start Date"
                fullWidth
                {...form.register("start_date")}
              />
              <TextField
                margin="dense"
                variant="outlined"
                type="date"
                label="End Date"
                fullWidth
                {...form.register("end_date")}
              />
              <ReactHookFormSelect
                label="status"
                variant="outlined"
                fullWidth
                control={form.control}
                name="status"
                defaultValue="Created"
                margin="dense"
              >
                <MenuItem value="Created">Created</MenuItem>
                <MenuItem value="In-process">In-process</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
              </ReactHookFormSelect>
              <TextField
                margin="dense"
                variant="outlined"
                label="Fax"
                fullWidth
                type="number"
                {...form.register("fax", {
                  valueAsNumber: true,
                })}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Email"
                fullWidth
                {...form.register("email")}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Street"
                fullWidth
                {...form.register("street")}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="City"
                fullWidth
                {...form.register("city")}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Zip"
                fullWidth
                {...form.register("zip", {
                  valueAsNumber: true,
                })}
                type="number"
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Activity"
                fullWidth
                {...form.register("activity")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Activity Status"
                fullWidth
                {...form.register("activity_status")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Activity Description"
                fullWidth
                {...form.register("activity_description")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Timing"
                fullWidth
                {...form.register("timing")}
              />

              <ReactHookFormSelect
                label="Created By"
                variant="outlined"
                fullWidth
                control={form.control}
                name="created_by"
                margin="dense"
                error={form.formState?.errors?.created_by}
              >
                {employees?.data?.map((e) => (
                  <MenuItem key={e?.id} value={e?.id}>
                    {e?.first_name} {e?.last_name}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>

              {createOrEdit?.mode === "edit" && typeof avatar === "string" ? (
                <div>
                  <img src={avatar} width={200} height={200} />
                </div>
              ) : null}

              <Input
                type="file"
                {...form.register("avatar")}
                error={form?.formState?.errors?.avatar}
              />
            </DialogContent>
            <DialogActions>
              <button
                className="edit-btn"
                onClick={() => {
                  setCreateOrEdit(null);
                }}
                type="button"
              >
                Close
              </button>
              <button
                className="edit-btn"
                type="submit"
                disabled={createProject.isLoading}
              >
                {createOrEdit?.mode === "create" ? "Create" : "Edit"}
              </button>
            </DialogActions>
          </form>
        </Dialog>

        <div className="flex justify-between items-center">
          <span className="font-semibold text-xl md:text-2xl">Projects</span>
          <span className="header-buttons">
            <button
              className="button"
              variant="contained"
              onClick={() => {
                setCreateOrEdit({
                  show: true,
                  mode: "create",
                });
              }}
            >
              Create Project
            </button>
          </span>
        </div>

        <div>
          <Paper className="w-full overflow-hidden">
            <TableContainer className="max-h-[580px]">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Project Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Customer Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Contact Person
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      View
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Edit
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Remove
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects?.data?.map((project, index) => (
                    <ProjectItem
                      project={project}
                      setOpenedProject={setOpenedProject}
                      key={index}
                      setCreateOrEdit={setCreateOrEdit}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </>
  );
};

const ProjectItem = ({ project, setOpenedProject, setCreateOrEdit }) => {
  const queryClient = useQueryClient();

  const removeProject = useMutation({
    mutationFn: async ({ project }) => {
      return await axios.get(
        `${backendHost}/api/projects/v1/remove/${project?.id}`
      );
    },
  });

  return (
    <TableRow>
      <TableCell
        style={{
          fontSize: 16,
        }}
      >
        {project?.name}
      </TableCell>
      <TableCell
        style={{
          fontSize: 16,
        }}
      >
        {project?.customer_name}
      </TableCell>
      <TableCell
        style={{
          fontSize: 16,
        }}
      >
        {project?.contact_person}
      </TableCell>
      <TableCell align="right">
        <button
          className="edit-btn view-btn"
          variant="contained"
          onClick={() => {
            setOpenedProject({
              show: true,
              mode: "view",
              project: project,
            });
          }}
        >
          View
        </button>
      </TableCell>
      <TableCell
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          className="edit-btn"
          onClick={() => {
            setCreateOrEdit({
              show: true,
              mode: "edit",
              data: project,
            });
          }}
        >
          Edit
        </button>
      </TableCell>
      <TableCell align="right">
        <button
          className="edit-btn delete-btn"
          disabled={removeProject.isLoading}
          onClick={() => {
            removeProject.mutate(
              {
                project: project,
              },
              {
                onSettled: () => {
                  queryClient.refetchQueries(["projects"]);
                },
              }
            );
          }}
        >
          Remove
        </button>
      </TableCell>
    </TableRow>
  );
};

export default Projects;
