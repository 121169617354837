import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Redirect } from "react-router-dom";
import { checkToken } from "../../lib/api";
import LoginNavBar from "./LoginNavBar";

const LoginButton = ({ state, action }) => {
  return (
    <button
      style={{
        cursor: "pointer",
      }}
      disabled={
        (!state.username.valid && state.username.entered) ||
        (!state.password.valid && state.password.entered)
      }
      onClick={(e) => action()}
      variant="contained"
      color="primary"
      size="large"
      className="w-full hover:bg-[#ec712e] hover:text-white flex mx-auto text-center rounded-3xl bg-white shadow-md text-[1.8rem] font-medium text-[#ec712e] justify-center items-center duration-300 transition-all border border-[#707070]"
    >
      Login
    </button>
  );
};

export const Login = ({ state, addUsername, addPassword, loginRequest }) => {
  return (
    <div>
      <LoginNavBar />
      <div className="flex justify-center h-[calc(100dvh-80px)] items-center">
        <div className="rounded-3xl bg-white border border-[#707070] md:py-12 md:px-24 px-6 py-3">
          <form className="space-y-6">
            <div>
              <div className="login__logo">
                <img src="/images/logo.jpeg" alt="app-logo" />
              </div>
              <TextField
                disabled={state.loading}
                error={state.username.entered && !state.username.valid}
                onChange={({ target: { value } }) => addUsername(value)}
                value={state.username.value}
                label="Username"
                className="text_field"
                inputProps={{ style: { fontSize: 20 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
            </div>
            <div>
              <TextField
                disabled={state.loading}
                error={state.password.entered && !state.password.valid}
                onChange={({ target: { value } }) => addPassword(value)}
                value={state.password.value}
                className="text_field"
                type="password"
                label="Password"
                inputProps={{ style: { fontSize: 20 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 20 } }}
              />
            </div>
            {state.loading ? (
              <LinearProgress />
            ) : (
              <LoginButton state={state} action={loginRequest} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export const LoginRoute = ({
  state,
  addUsername,
  addPassword,
  loginRequest,
}) => {
  return checkToken() ? (
    <Redirect to="/app" />
  ) : (
    <Login
      state={state}
      addUsername={addUsername}
      addPassword={addPassword}
      loginRequest={loginRequest}
    />
  );
};
