export const login = {
    ADD_USERNAME: 'ADD_USERNAME_LOGIN',
    ADD_PASSWORD: 'ADD_PASSWORD_LOGIN',
    LOGIN: 'LOGIN',
    LOGIN_SUCCESSFUL: 'LOGIN_SUCCESSFUL',
    LOGIN_FAILED: 'LOGIN_FAILED'
};

export const error = {
    ADD_ERROR: 'ADD_ERROR',
    REMOVE_ERROR: 'REMOVE_ERROR'
};

export const tasks = {
    GET_TASKS: 'GET_TASKS',
    GET_TASKS_SUCCESSFUL: 'GET_TASKS_SUCCESSFUL',
    GET_TASKS_FAILED: 'GET_TASKS_FAILED',
    REMOVE_TASK: 'REMOVE_TASK',
    DELETE_TASK: 'DELETE_TASK',
    DELETE_TASK_SUCCESSFUL: 'DELETE_TASK_SUCCESSFUL',
    DELETE_TASK_FAILED: 'DELETE_TASK_FAILED',
    OPEN_CREATE_TASK: 'OPEN_CREATE_TASK',
    CLOSE_CREATE_TASK: 'CLOSE_CREATE_TASK',
    CREATE_TASK: 'CREATE_TASK',
    CREATE_TASK_SUCCESSFUL: 'CREATE_TASK_SUCCESSFUL',
    CREATE_TASK_FAILED: 'CREATE_TASK_FAILED',
    OPEN_EDIT_TASK: 'OPEN_EDIT_TASK',
    EDIT_TASK: 'EDIT_TASK',
    EDIT_TASK_SUCCESSFUL: 'EDIT_TASK_SUCCESSFUL',
    EDIT_TASK_FAILED: 'EDIT_TASK_FAILED',
    ADD_TASK: 'ADD_TASK',
    CHECKED: 'TASK_CHECKED',
    ADD_NAME: 'ADD_NAME',
    ADD_DESCRIPTION: 'ADD_DESCRIPTION'
};

export const projects = {
    GET_PROJECTS: 'GET_PROJECTS',
    GET_PROJECTS_SUCCESSFUL: 'GET_PROJECTS_SUCCESSFUL',
    GET_PROJECTS_FAILED: 'GET_PROJECTS_FAILED',
    REMOVE_PROJECT: 'REMOVE_PROJECT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    DELETE_PROJECT_SUCCESSFUL: 'DELETE_PROJECT_SUCCESSFUL',
    DELETE_PROJECT_FAILED: 'DELETE_PROJECT_FAILED',
    OPEN_CREATE_PROJECT: 'OPEN_CREATE_PROJECT',
    CLOSE_CREATE_PROJECT: 'CLOSE_CREATE_PROJECT',
    CREATE_PROJECT: 'CREATE_PROJECT',
    CREATE_PROJECT_SUCCESSFUL: 'CREATE_PROJECT_SUCCESSFUL',
    CREATE_PROJECT_FAILED: 'CREATE_PROJECT_FAILED',
    OPEN_EDIT_PROJECT: 'OPEN_PROJECT_PROJECT',
    EDIT_PROJECT: 'EDIT_PROJECT',
    EDIT_PROJECT_SUCCESSFUL: 'EDIT_PROJECT_SUCCESSFUL',
    EDIT_PROJECT_FAILED: 'EDIT_PROJECT_FAILED',
    ADD_PROJECT_NAME: 'P_ADD_PROJECT_NAME',
    ADD_CUSTOMER_NAME: 'P_ADD_CUSTOMER_NAME',
    ADD_CONTACT_PERSON: 'P_ADD_CONTACT_PERSON',
    ADD_EMAIL: 'P_ADD_EMAIL',
    ADD_PHONE: 'P_ADD_PHONE',
    ADD_FAX: 'P_ADD_FAX',
    ADD_START_DATE: 'P_ADD_START_DATE',
    ADD_END_DATE: 'P_ADD_END_DATE',
    ADD_CITY: 'P_ADD_CITY',
    ADD_ADDRESS: 'P_ADD_ADDRESS',
    CHECKED: 'PROJECT_CHECKED',
    VIEW_PROJECT: 'VIEW_PROJECT',
    CLOSE_VIEW_PROJECT: 'CLOSE_VIEW_PROJECT'
};

export const resources = {
    GET_RESOURCES: 'GET_RESOURCES',
    GET_RESOURCES_SUCCESSFUL: 'GET_RESOURCES_SUCCESSFUL',
    GET_RESOURCES_FAILED: 'GET_RESOURCES_FAILED',
    REMOVE_RESOURCE: 'REMOVE_RESOURCE',
    DELETE_RESOURCE: 'DELETE_RESOURCE',
    DELETE_RESOURCE_SUCCESSFUL: 'DELETE_RESOURCE_SUCCESSFUL',
    DELETE_RESOURCE_FAILED: 'DELETE_RESOURCE_FAILED',
    ADD_RNR: 'R_ADD_RNR',
    ADD_RNAME: 'R_ADD_RNAME',
    ADD_VALID_FROM: 'R_ADD_VALID_FROM',
    ADD_VALID_TO: 'R_ADD_VALID_TO',
    ADD_STORE: 'R_ADD_STORE',
    ADD_ARTICLE: 'R_ADD_ARTICLE',
    ADD_LONG_NAME: 'R_ADD_LONG_NAME',
    ADD_BSECTOR: 'R_ADD_BUSINESS_SECTOR',
    ADD_ADDRESS: 'R_ADD_ADDRESS',
    ADD_GROUP: 'R_ADD_GROUP',
    OPEN_CREATE_RESOURCE: 'OPEN_CREATE_RESOURCE',
    CLOSE_CREATE_RESOURCE: 'CLOSE_CREATE_RESOURCE',
    CREATE_RESOURCE: 'CREATE_RESOURCE',
    CREATE_RESOURCE_SUCCESSFUL: 'CREATE_RESOURCE_SUCCESSFUL',
    CREATE_RESOURCE_FAILED: 'CREATE_RESOURCE_FAILED',
    OPEN_EDIT_RESOURCE: 'OPEN_EDIT_RESOURCE',
    EDIT_RESOURCE: 'EDIT_RESOURCE',
    EDIT_RESOURCE_SUCCESSFUL: 'EDIT_RESOURCE_SUCCESSFUL',
    EDIT_RESOURCE_FAILED: 'EDIT_RESOURCE_FAILED',
    CHECK_PROMPT: 'R_CHECK_PROMPT',
    VIEW_RESOURCE: 'VIEW_RESOURCE',
    CLOSE_VIEW_RESOURCE: 'CLOSE_VIEW_RESOURCE'
};

export const login_status = {
    GET_LOGIN_STATUS: 'GET_LOGIN_STATUS',
    GET_LOGIN_STATUS_SUCCESSFUL: 'GET_LOGIN_STATUS_SUCCESSFUL',
    GET_LOGIN_STATUS_FAILED: 'GET_LOGIN_STATUS_FAILED',
    GET_LOGIN_STATUS_DATE: 'GET_LOGIN_STATUS_DATE'
};

export const customers = {
    GET_CUSTOMERS: 'GET_CUSTOMERS',
    GET_CUSTOMERS_SUCCESSFUL: 'GET_CUSTOMERS_SUCCESSFUL',
    GET_CUSTOMERS_FAILED: 'GET_CUSTOMERS_FAILED',
    REMOVE_CUSTOMER: 'REMOVE_CUSTOMER',
    DELETE_CUSTOMER: 'DELETE_CUSTOMER',
    DELETE_CUSTOMER_SUCCESSFUL: 'DELETE_CUSTOMER_SUCCESSFUL',
    DELETE_CUSTOMER_FAILED: 'DELETE_CUSTOMER_FAILED',
    VIEW_CUSTOMER: 'VIEW_CUSTOMER',
    CLOSE_VIEW_CUSTOMER: 'CLOSE_VIEW_CUSTOMER',
    OPEN_CREATE_CUSTOMER: 'OPEN_CREATE_CUSTOMER',
    CLOSE_CREATE_CUSTOMER: 'CLOSE_CREATE_CUSTOMER',
    ADD_CNAME: 'C_ADD_CNAME',
    ADD_DNUM: 'C_ADD_DNUM',
    ADD_CREATION_DATE: 'C_ADD_CREATION_DATE',
    ADD_EDITED_ON: 'C_ADD_EDITED_ON',
    ADD_EDITED_BY: 'C_ADD_EDITED_BY',
    ADD_SALUTATION: 'C_ADD_SALUTATION',
    ADD_PHONE: 'C_ADD_PHONE',
    ADD_EMAIL: 'C_ADD_EMAIL',
    ADD_WEBSITE: 'C_ADD_WEBSITE',
    ADD_ADDR_CITY: 'C_ADD_ADDR_CITY',
    ADD_ADDR: 'C_ADD_ADDR',
    ADD_ADDR_POSTCODE: 'C_ADD_ADDR_POSTCODE',
    ADD_ADDR_CCODE: 'C_ADD_ADDR_CCODE',
    ADD_POSTBOX_NAME: 'C_ADD_POSTBOX_NAME',
    ADD_POSTBOX_POSTCODE: 'C_ADD_POSTBOX_POSTCODE',
    ADD_POSTBOX_CITY: 'C_ADD_POSTBOX_CITY',
    ADD_POSTBOX_CCODE: 'C_ADD_POSTBOX_CCODE',
    ADD_BANK: 'C_ADD_BANK',
    ADD_BANK_CODE: 'C_ADD_BANK_CODE',
    ADD_BANK_ACCOUNT: 'C_ADD_BANK_ACCOUNT',
    ADD_BANK_USER: 'C_ADD_BANK_USER',
    ADD_BANK_IBAN: 'C_ADD_BANK_IBAN',
    ADD_BANK_BIC: 'C_ADD_BANK_BIC',
    ADD_BANK_CCODE: 'C_ADD_BANK_CCODE',
    ADD_BANK_CURRENCY: 'C_ADD_BANK_CURRENCY',
    OPEN_EDIT_CUSTOMER: 'OPEN_EDIT_CUSTOMER',
    CREATE_CUSTOMER: 'CREATE_CUSTOMER',
    CREATE_CUSTOMER_SUCCESSFUL: 'CREATE_CUSTOMER_SUCCESSFUL',
    CREATE_CUSTOMER_FAILED: 'CREATE_CUSTOMER_FAILED',
    EDIT_CUSTOMER: 'EDIT_CUSTOMER',
    EDIT_CUSTOMER_SUCCESSFUL: 'EDIT_CUSTOMER_SUCCESSFUL',
    EDIT_CUSTOMER_FAILED: 'EDIT_CUSTOMER_FAILED',
    CHECK_PROMPT: 'C_CHECK_PROMPT'
};

export const employees = {
    GET_EMPLOYEES: 'GET_EMPLOYEES',
    GET_EMPLOYEES_SUCCESSFUL: 'GET_EMPLOYEES_SUCCESSFUL',
    GET_EMPLOYEES_FAILED: 'GET_EMPLOYEES_FAILED',
    REMOVE_EMPLOYEE: 'REMOVE_EMPLOYEE',
    DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',
    DELETE_EMPLOYEE_SUCCESSFUL: 'DELETE_EMPLOYEE_SUCCESSFUL',
    DELETE_EMPLOYEE_FAILED: 'DELETE_EMPLOYEE_FAILED',
    VIEW_EMPLOYEE: 'VIEW_EMPLOYEE',
    CLOSE_VIEW_EMPLOYEE: 'CLOSE_VIEW_EMPLOYEE',
    OPEN_AUTH_EMPLOYEE: 'OPEN_AUTH_EMPLOYEE',
    CLOSE_AUTH_EMPLOYEE: 'CLOSE_AUTH_EMPLOYEE',
    AUTH_EMPLOYEE: 'AUTH_EMPLOYEE',
    AUTH_EMPLOYEE_SUCCESSFUL: 'AUTH_EMPLOYEE_SUCCESSFUL',
    AUTH_EMPLOYEE_FAILED: 'AUTH_EMPLOYEE_FAILED',
    CHANGE_AUTH: 'CHANGE_AUTH',
    AUTH_UPDATE: 'AUTH_UPDATE',
    AUTH_UPDATE_SUCCESSFUL: 'AUTH_UPDATE_SUCCESSFUL',
    AUTH_UPDATE_FAILED: 'AUTH_UPDATE_FAILED',
    REMOVE_AUTH: 'REMOVE_AUTH',
    OPEN_CREATE_EMP: 'OPEN_CREATE_EMP',
    CLOSE_CREATE_EMP: 'CLOSE_CREATE_EMP',
    OPEN_EDIT_EMP: 'OPEN_EDIT_EMP',
    CREATE_EMP: 'CREATE_EMPLOYEE',
    CREATE_EMP_SUCCESSFUL: 'CREATE_EMPLOYEE_SUCCESSFUL',
    CREATE_EMP_FAILED: 'CREATE_EMPLOYEE_FAILED',
    EDIT_EMP: 'EDIT_EMPLOYEE',
    EDIT_EMP_SUCCESSFUL: 'EDIT_EMPLOYEE_SUCCESSFUL',
    EDIT_EMP_FAILED: 'EDIT_EMPLOYEE_FAILED',
    ADD_INFO: 'ADD_INFO',
    CHECK_PROMPT: 'E_CHECK_PROMPT',
    REMOVE_EMP: 'REMOVE_EMP'
};

export const complains = {
    GET_COMPLAINS: 'GET_COMPLAINS',
    GET_COMPLAINS_SUCCESSFUL: 'GET_COMPLAINS_SUCCESSFUL',
    GET_COMPLAINS_FAILED: 'GET_COMPLAINS_FAILED',
    OPEN_CREATE_CATEGORY: 'OPEN_CREATE_CATEGORY',
    CLOSE_CREATE_CATEGORY: 'CLOSE_CREATE_CATEGORY',
    REMOVE_COMPLAIN: 'REMOVE_COMPLAIN',
    DELETE_COMPLAIN: 'DELETE_COMPLAIN',
    DELETE_COMPLAIN_SUCCESSFUL: 'DELETE_COMPLAIN_SUCCESSFUL',
    DELETE_COMPLAIN_FAILED: 'DELETE_COMPLAIN_FAILED',
    CHANGE_VIEW: 'CHANGE_VIEW',
    GET_COMPLAIN_MAP: 'GET_COMPLAIN_MAP',
    GET_COMPLAIN_MAP_SUCCESSFUL: 'GET_COMPLAIN_MAP_SUCCESSFUL',
    GET_COMPLAIN_MAP_FAILED: 'GET_COMPLAIN_MAP_FAILED',
    GET_CANALYSIS: 'GET_CANALYSIS',
    GET_CANALYSIS_SUCCESSFUL: 'GET_CANALYSIS_SUCCESSFUL',
    GET_CANALYSIS_FAILED: 'GET_CANALYSIS_FAILED',
    GET_CATEGORIES: 'GET_CATGORIES',
    GET_CATEGORIES_SUCCESSFUL: 'GET_CATEGORIES_SUCCESSFUL',
    GET_CATEGORIES_FAILED: 'GET_CATEGORIES_FAILED',
    OPEN_CREATE_CAT: 'OPEN_CREATE_CAT',
    CLOSE_CREATE_CAT: 'CLOSE_CREATE_CAT',
    ADD_CAT: 'ADD_CAT',
    CREATE_CAT: 'CREATE_CAT',
    CREATE_CAT_SUCCESSFUL: 'CREATE_CAT_SUCCESSFUL',
    CREATE_CAT_FAILED: 'CREATE_CAT_FAILED',
    OPEN_VIEW: 'COM_OPEN_VIEW',
    CLOSE_VIEW: 'COM_CLOSE_VIEW',
    MODIFY_STATUS: 'COM_MODIFY_STATUS',
    CHANGE_STATUS: 'COM_CHANGE_STATUS',
    CHANGE_STATUS_SUCCESSFUL: 'COM_CHANGE_STATUS_SUCCESSFUL',
    CHANGE_STATUS_FAILED: 'COM_CHANGE_STATUS_FAILED'
};

export const wo = {
    GET_WO: 'GET_WO',
    GET_WO_SUCCESSFUL: 'GET_WO_SUCCESSFUL',
    GET_WO_FAILED: 'GET_WO_FAILED',
    REMOVE_WO: 'REMOVE_WO',
    DELETE_WO: 'DELETE_WO',
    DELETE_WO_SUCCESSFUL: 'DELETE_WO_SUCCESSFUL',
    DELETE_WO_FAILED: 'DELETE_WO_FAILED',
    VIEW_WO: 'VIEW_WO',
    CLOSE_WO: 'CLOSE_WO',
    OPEN_CREATE_WO: 'OPEN_CREATE_WO',
    CLOSE_CREATE_WO: 'CLOSE_CREATE_WO',
    OPEN_EDIT_WO: 'OPEN_EDIT_WO',
    CREATE_WO: 'CREATE_WO',
    CREATE_WO_SUCCESSFUL: 'CREATE_WO_SUCCESSFUL',
    CREATE_WO_FAILED: 'CREATE_WO_FAILED',
    EDIT_WO: 'EDIT_WO',
    EDIT_WO_SUCCESSFUL: 'EDIT_WO_SUCCESSFUL',
    EDIT_WO_FAILED: 'EDIT_WO_FAILED',
    ADD_INFO: 'WO_ADD_INFO',
    CHECK_PROMPT: 'WO_CHECK_PROMPT'
};

