export let backendHost;

const hostname = window && window.location && window.location.hostname;

// https://tchapmeji-server.herokuapp.com/

// if (hostname === 'localhost') {
// 	backendHost = 'http://localhost:3000'
// } else {
// 	backendHost = 'http://ec2-3-14-85-63.us-east-2.compute.amazonaws.com:3000'
// }

// backendHost = "http://localhost:3001";
// backendHost = 'https://tchapmeji-server.herokuapp.com'
// backendHost = 'https://tchapmejibackend.herokuapp.com'
backendHost = "https://hapi-production-17aa.up.railway.app";
export const getAPIRoot = (endpoint, apiVersion) =>
  `${backendHost}/api/${endpoint}/${apiVersion}`;
