import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import format from "date-fns/format";
import axios from "axios";
import { getAPIRoot } from "../config";
import Ruler from "./Ruler";
import AuthenticatedNavbar from "./authenticatedNavbar/AuthenticatedNavbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Map, Marker, Overlay } from "pigeon-maps";

const Test = (props) => {
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [infoState, setInfoState] = useState(false);

  const getData = async (date) => {
    setIsLoading(true);
    let API_ROOT = getAPIRoot("timings", "v1");
    let data = await axios.post(API_ROOT + "/dailywh/getall", {
      date: date,
    });
    data = data.data.data;
    let x = data.filter((d) => d.data.length !== 0);
    setData(x);
    setIsLoading(false);
  };

  useEffect(() => {
    let newDate = format(new Date(date), "yyyy-MM-dd");
    getData(newDate);
  }, []);

  const getSameHourDiff = (from, to) => {
    let minsCal = 0;
    for (let i = from; i <= to; i++) {
      minsCal += 1;
      if (i % 100 > 58) {
        i += 41;
      }
    }
    return minsCal;
  };

  const getTimeString = (mins) => {
    if (mins <= 59) {
      return `${mins} Minutes`;
    } else {
      let hours = parseInt(mins / 60);
      let minutes = mins % 60;
      return `${hours} hours, ${minutes} mins`;
    }
  };

  const onMarkerClicked = () => {
    setInfoState(!infoState);
  };

  return (
    <>
      <AuthenticatedNavbar />
      <div className="container mx-auto space-y-4 md:pt-10 pb-[70px] pt-4 px-2">
        <div>
          <TextField
            type="date"
            value={date}
            onChange={(e) => {
              let newDate = format(new Date(e.target.value), "yyyy-MM-dd");
              getData(newDate);
              setDate(newDate);
            }}
          />

          {mapData && (
            <>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="edit-btn close-btn"
                    onClick={() => {
                      setMapData(null);
                    }}
                  >
                    Close
                  </button>
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "88%",
                    height: "400px",
                    background: "#fff",
                  }}
                >
                  <Map
                    center={[mapData?.lat, mapData?.lng]}
                    zoom={8}
                    metaWheelZoom={true}
                  >
                    <Marker
                      anchor={[mapData?.lat, mapData?.lng]}
                      onClick={onMarkerClicked}
                    />
                    <Overlay
                      anchor={[mapData?.lat, mapData?.lng]}
                      //   offset={[120, 79]}
                    >
                      <div
                        style={{
                          background: "white",
                          padding: 10,
                          borderRadius: 10,
                        }}
                      >
                        <div>Name: {mapData?.name}</div>
                        <div>Work order: {mapData?.woNum}</div>
                        <div>Customer Name: {mapData?.cusName}</div>
                      </div>
                    </Overlay>
                  </Map>
                </div>
              </div>
            </>
          )}

          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              {data?.map((wo_app) => {
                return (
                  <>
                    <div style={{ marginTop: 30, marginBottom: 20 }}>
                      <div>
                        Work Time:{" "}
                        {getTimeString(
                          getSameHourDiff(
                            wo_app.data[0].working_from,
                            wo_app.data[0].working_to
                          )
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <div>
                          <Ruler wo_app={wo_app} />
                        </div>
                        <div style={{ marginLeft: 25 }}>
                          <button
                            className="edit-btn view-btn"
                            onClick={async () => {
                              setMapData({
                                lat: wo_app?.data?.[0]?.latitude ?? 51.1657,
                                lng: wo_app?.data?.[0]?.longitude ?? 10.4515,
                                name: wo_app.data[0].first_name,
                                woNum: wo_app.data[0].wo_app,
                                cusName: wo_app.data[0].name,
                              });
                            }}
                          >
                            View on Map
                          </button>
                        </div>
                      </div>
                      <hr
                        style={{
                          marginTop: 30,
                          border: "1px solid #e3e3e3",
                        }}
                      ></hr>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Test;
// export default GoogleApiWrapper({
//   apiKey: "AIzaSyDkKCHsqBYTsP49Czl_NvOZb46bZ7SWZZY",
// })(Test);
