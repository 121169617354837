import React from "react";
import { PieChart } from "react-chartkick";
import "chart.js";
import { useQuery } from "react-query";
import axios from "axios";
import { backendHost } from "../../config";
import LinearProgress from "@material-ui/core/LinearProgress";

export const CategoryAnalysis = () => {
  const canalysis = useQuery({
    queryKey: "canalysis",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/complains/v1/canalysis`);
      return res?.data?.data?.canalysis;
    },
  });

  const shape = canalysis.data?.map((c) => [c.name, c.complains]);

  if (canalysis.isLoading) return <LinearProgress />;

  return (
    <center>
      <PieChart data={shape ?? []} />
    </center>
  );
};
