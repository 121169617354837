import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import { MenuItem, TableHead } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AuthenticatedNavbar from "./../authenticatedNavbar/AuthenticatedNavbar";
import { useQueryClient, useQuery, useMutation } from "react-query";
import axios from "axios";
import { backendHost } from "../../config";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ReactHookFormSelect from "../ReactHookFormSelect";
import { TableContainer } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import "./../global.css";

const customerSchema = z.object({
  id: z.any(),
  name: z.string().min(1),
  debitor_number: z.number().positive(),
  salutation: z.string().nullish(),
  sign: z.string().min(1),
  phone: z.number().positive(),
  fax: z.number().optional(),
  email: z.string().email(),
  website: z.string().nullish(),
  address: z.string().nullish(),
  city: z.string().nullish(),
  zip: z.number().optional(),
  country_code: z.string().nullish(),
  postbox_name: z.string().nullish(),
  postbox_city: z.string().nullish(),
  postbox_zip: z.number().optional(),
  bank_name: z.string().nullish(),
  bank_code: z.number().optional(),
  bank_account: z.number().optional(),
  bank_user: z.string().nullish(),
  iban: z.number().optional(),
  bic: z.number().optional(),
  currency: z.string().nullish(),
});

const Customers = () => {
  const queryClient = useQueryClient();
  const [openedCustomer, setOpenedCustomer] = useState(null);
  const [createOrEdit, setCreateOrEdit] = useState(null);

  const customers = useQuery({
    queryKey: "customers",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/customers/v1/customers`);
      return res?.data?.data?.customers;
    },
  });

  const createCustomer = useMutation({
    mutationFn: async (data) => {
      return await axios.post(
        `${backendHost}/api/customers/v1/create-app`,
        data
      );
    },
  });

  const updateCustomer = useMutation({
    mutationFn: async (data) => {
      return await axios.post(
        `${backendHost}/api/customers/v1/edit-customer/${data?.id}`,
        data
      );
    },
  });

  const defaultFormValues = {
    name: createOrEdit?.data?.name ?? "",
    debitor_number: createOrEdit?.data?.debitor_number ?? 0,
    salutation: createOrEdit?.data?.salutation ?? "Mr",
    phone: createOrEdit?.data?.phone ?? 0,
    fax: createOrEdit?.data?.fax ?? null,
    email: createOrEdit?.data?.email ?? "",
    website: createOrEdit?.data?.website ?? "",
    address: createOrEdit?.data?.address ?? "",
    city: createOrEdit?.data?.city ?? "",
    zip: createOrEdit?.data?.zip ?? null,
    country_code: createOrEdit?.data?.country_code ?? null,
    postbox_name: createOrEdit?.data?.postbox_name ?? "",
    postbox_city: createOrEdit?.data?.postbox_city ?? "",
    postbox_zip: createOrEdit?.data?.postbox_zip ?? null,
    bank_name: createOrEdit?.data?.bank_name ?? "",
    bank_code: createOrEdit?.data?.bank_code ?? null,
    bank_account: createOrEdit?.data?.bank_account ?? null,
    bank_user: createOrEdit?.data?.bank_user ?? "",
    iban: createOrEdit?.data?.iban ?? null,
    bic: createOrEdit?.data?.bic ?? null,
    currency: createOrEdit?.data?.currency ?? "",
    sign: createOrEdit?.data?.sign ?? "",
    id: createOrEdit?.data?.id ?? null,
  };

  const form = useForm({
    resolver: zodResolver(customerSchema),
    defaultValues: defaultFormValues,
  });

  const onSubmit = async (data) => {
    if (createOrEdit?.mode === "create") {
      createCustomer.mutate(
        {
          name: data?.name,
          debitor_number: data?.debitor_number,
          salutation: data?.salutation,
          phone: data?.phone,
          fax: data?.fax,
          email: data?.email,
          website: data?.website,
          address: data?.address,
          city: data?.city,
          zip: data?.zip,
          country_code: data?.country_code,
          postbox_name: data?.postbox_name,
          postbox_city: data?.postbox_city,
          postbox_zip: data?.postbox_zip,
          bank_name: data?.bank_name,
          bank_code: data?.bank_code,
          bank_account: data?.bank_account,
          bank_user: data?.bank_user,
          iban: data?.iban,
          bic: data?.bic,
          currency: data?.currency,
          sign: data?.sign,
        },
        {
          onSettled: () => {
            queryClient.refetchQueries(["customers"]);
            setCreateOrEdit(null);
          },
        }
      );
    } else {
      updateCustomer.mutate(
        {
          name: data?.name,
          debitor_number: data?.debitor_number,
          salutation: data?.salutation,
          phone: data?.phone,
          fax: data?.fax,
          email: data?.email,
          website: data?.website,
          address: data?.address,
          city: data?.city,
          zip: data?.zip,
          country_code: data?.country_code,
          postbox_name: data?.postbox_name,
          postbox_city: data?.postbox_city,
          postbox_zip: data?.postbox_zip,
          bank_name: data?.bank_name,
          bank_code: data?.bank_code,
          bank_account: data?.bank_account,
          bank_user: data?.bank_user,
          iban: data?.iban,
          bic: data?.bic,
          currency: data?.currency,
          sign: data?.sign,
          id: data?.id,
        },
        {
          onSettled: () => {
            queryClient.refetchQueries(["projects"]);
            setCreateOrEdit(null);
          },
        }
      );
    }
  };

  useEffect(() => {
    form.reset(defaultFormValues);
  }, [createOrEdit?.show]);

  return (
    <>
      <AuthenticatedNavbar />
      <div className="container mx-auto space-y-4 md:pt-10 pb-[70px] pt-4 px-2">
        {/* View Modal */}
        <Dialog
          open={!!openedCustomer?.show}
          PaperProps={{
            style: {
              borderRadius: 20,
              margin: 0,
            },
          }}
        >
          <DialogTitle style={{ fontWeight: "bold" }}>
            {openedCustomer?.customer?.name}
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Customer Name
                  </TableCell>
                  <TableCell>{openedCustomer?.customer?.name}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Debitor Number
                  </TableCell>
                  <TableCell>
                    {openedCustomer?.customer?.debitor_number}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Salutation
                  </TableCell>
                  <TableCell>{openedCustomer?.customer?.salutation}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Phone Sign
                  </TableCell>
                  <TableCell>{openedCustomer?.customer?.sign}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Phone</TableCell>
                  <TableCell>{openedCustomer?.customer?.phone}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Fax</TableCell>
                  <TableCell>{openedCustomer?.customer?.fax}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                  <TableCell>{openedCustomer?.customer?.email}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Website</TableCell>
                  <TableCell>{openedCustomer?.customer?.website}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
                  <TableCell>{openedCustomer?.customer?.address}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
                  <TableCell>{openedCustomer?.customer?.city}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Zip</TableCell>
                  <TableCell>{openedCustomer?.customer?.zip}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Country Code
                  </TableCell>
                  <TableCell>
                    {openedCustomer?.customer?.country_code}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Postbox Name
                  </TableCell>
                  <TableCell>
                    {openedCustomer?.customer?.postbox_name}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Postbox Zip
                  </TableCell>
                  <TableCell>{openedCustomer?.customer?.postbox_zip}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Bank Name
                  </TableCell>
                  <TableCell>{openedCustomer?.customer?.bank_name}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Bank Code
                  </TableCell>
                  <TableCell>{openedCustomer?.customer?.bank_code}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Bank Account
                  </TableCell>
                  <TableCell>
                    {openedCustomer?.customer?.bank_account}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Bank User
                  </TableCell>
                  <TableCell>{openedCustomer?.customer?.bank_user}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>IBAN</TableCell>
                  <TableCell>{openedCustomer?.customer?.iban}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>BIC</TableCell>
                  <TableCell>{openedCustomer?.customer?.bic}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Currency</TableCell>
                  <TableCell>{openedCustomer?.customer?.currency}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <button
              className="edit-btn"
              color="secondary"
              onClick={() => {
                setOpenedCustomer(null);
              }}
            >
              Close
            </button>
          </DialogActions>
        </Dialog>

        {/* Create/Update Modal */}
        <Dialog
          open={!!createOrEdit?.show}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: 20,
              margin: 0,
            },
          }}
        >
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogTitle>
              {createOrEdit?.mode === "create" ? "Create" : "Edit"} Customer
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                variant="outlined"
                label="Customer Name"
                fullWidth
                {...form.register("name")}
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Debitor Number"
                fullWidth
                {...form.register("debitor_number", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <ReactHookFormSelect
                label="Salutation"
                variant="outlined"
                fullWidth
                control={form.control}
                name="salutation"
                margin="dense"
              >
                <MenuItem value="Mr">Mr</MenuItem>
                <MenuItem value="Dr">Dr</MenuItem>
                <MenuItem value="Mrs">Mrs</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
              </ReactHookFormSelect>

              <ReactHookFormSelect
                label="Phone Sign"
                variant="outlined"
                fullWidth
                control={form.control}
                name="sign"
                margin="dense"
                defaultValue="+"
              >
                <MenuItem value="+">+</MenuItem>
                <MenuItem value="-">-</MenuItem>
              </ReactHookFormSelect>

              <TextField
                margin="dense"
                variant="outlined"
                label="Phone"
                fullWidth
                {...form.register("phone", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Fax"
                fullWidth
                {...form.register("fax", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Email"
                fullWidth
                {...form.register("email")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Website"
                fullWidth
                {...form.register("website")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Address"
                fullWidth
                {...form.register("address")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="City"
                fullWidth
                {...form.register("city")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Zip"
                fullWidth
                {...form.register("zip", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Country Code"
                fullWidth
                {...form.register("country_code")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Postbox Name"
                fullWidth
                {...form.register("postbox_name")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Postbox City"
                fullWidth
                {...form.register("postbox_city")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Postbox Zip"
                fullWidth
                {...form.register("postbox_zip", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Bank Name"
                fullWidth
                {...form.register("bank_name")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Bank Code"
                fullWidth
                {...form.register("bank_code", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Bank Account"
                fullWidth
                {...form.register("bank_account", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Bank User"
                fullWidth
                {...form.register("bank_user")}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="IBAN"
                fullWidth
                {...form.register("iban", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="BIC"
                fullWidth
                {...form.register("bic", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Currency"
                fullWidth
                {...form.register("currency")}
              />
            </DialogContent>

            <DialogActions>
              <button
                className="edit-btn"
                onClick={() => {
                  setCreateOrEdit(null);
                }}
                type="button"
              >
                Close
              </button>
              <button
                className="edit-btn"
                type="submit"
                disabled={createCustomer.isLoading}
              >
                {createOrEdit?.mode === "create" ? "Create" : "Edit"}
              </button>
            </DialogActions>
          </form>
        </Dialog>

        <div className="flex justify-between items-center">
          <span className="font-semibold text-xl md:text-2xl">Customer</span>
          <span className="header-buttons">
            <button
              className="button"
              variant="contained"
              onClick={() => {
                setCreateOrEdit({
                  show: true,
                  mode: "create",
                });
              }}
            >
              Create Customer
            </button>
          </span>
        </div>
        <div>
          <Paper className="w-full overflow-hidden">
            <TableContainer className="max-h-[580px]">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Customer Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Debitor Number
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      View
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Edit
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      align="right"
                    >
                      Remove
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers?.data?.map((customer, index) => (
                    <CustomerItem
                      customer={customer}
                      key={index}
                      setOpenedCustomer={setOpenedCustomer}
                      setCreateOrEdit={setCreateOrEdit}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </>
  );
};

const CustomerItem = ({ customer, setOpenedCustomer, setCreateOrEdit }) => {
  const queryClient = useQueryClient();

  const removeCustomer = useMutation({
    mutationFn: async ({ customer }) => {
      return await axios.get(
        `${backendHost}/api/customers/v1/remove/${customer?.id}`
      );
    },
  });

  return (
    <>
      <TableRow>
        <TableCell
          style={{
            fontSize: 16,
          }}
        >
          {customer?.name}
        </TableCell>
        <TableCell
          style={{
            fontSize: 16,
          }}
        >
          {customer?.debitor_number}
        </TableCell>
        <TableCell align="right">
          <button
            className="edit-btn view-btn"
            variant="contained"
            onClick={() => {
              setOpenedCustomer({
                show: true,
                mode: "view",
                customer: customer,
              });
            }}
          >
            View
          </button>
        </TableCell>
        <TableCell align="right">
          <button
            className="edit-btn"
            onClick={() => {
              setCreateOrEdit({
                show: true,
                mode: "edit",
                data: customer,
              });
            }}
          >
            Edit
          </button>
        </TableCell>
        <TableCell align="right">
          <button
            className="edit-btn delete-btn"
            disabled={removeCustomer.isLoading}
            onClick={() => {
              removeCustomer.mutate(
                {
                  customer: customer,
                },
                {
                  onSettled: () => {
                    queryClient.refetchQueries(["customers"]);
                  },
                }
              );
            }}
          >
            Remove
          </button>
        </TableCell>
      </TableRow>
    </>
  );
};

// class Customers extends Component {
//   componentDidMount() {
//     this.props.getCustomers();
//   }

//   render() {
//     let actions = {
//       deleteCustomer: this.props.deleteCustomer,
//       openEditCustomer: this.props.openEditCustomer,
//       viewCustomer: this.props.viewCustomer,
//     };

//     let state = this.props.state;
//     let {
//       customer_name,
//       debitor_number,
//       creation_date,
//       edited_on,
//       edited_by,
//       salutation,
//       phone,
//       email,
//       website,
//       address_city,
//       address,
//       address_postcode,
//       address_countrycode,
//       postbox_name,
//       postbox_postcode,
//       postbox_city,
//       postbox_countrycode,
//       bank,
//       bank_code,
//       bank_account,
//       bank_user,
//       bank_iban,
//       bank_bic,
//       bank_countrycode,
//       bank_currency,
//     } = state.customer_details;

//     let disabled =
//       !customer_name.valid ||
//       !debitor_number.valid ||
//       !creation_date.valid ||
//       !edited_on.valid ||
//       !edited_by.valid ||
//       !salutation.valid ||
//       !phone.valid ||
//       !email.valid ||
//       (state.actions.edit_customer.id !== null &&
//         !state.actions.edit_customer.checked);
//     let view_customer_open = state.actions.view_customer.open;
//     let opened_customer = state.actions.view_customer.customer;
//     let create_customer_open = state.open;
//     return (
//       <>
//         <AuthenticatedNavbar />
//         <div className="container">
//           {/* CREATE CUSTOMER */}
//           <Dialog
//             open={create_customer_open}
//             PaperProps={{
//               style: {
//                 borderRadius: 20,
//                 padding: "10px 5px",
//               },
//             }}
//           >
//             <DialogTitle style={{ fontWeight: "bold" }}>
//               {state.actions.edit_customer.id !== null ? "Edit" : "Create"}
//             </DialogTitle>
//             <DialogContent>
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Customer Name"
//                 fullWidth
//                 value={customer_name.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!customer_name.valid && customer_name.entered}
//                 onChange={({ target: { value } }) => this.props.addCName(value)}
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Debitor Number"
//                 fullWidth
//                 value={debitor_number.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!debitor_number.valid && debitor_number.entered}
//                 onChange={({ target: { value } }) => this.props.addDNum(value)}
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Creation Date"
//                 fullWidth
//                 value={
//                   state.actions.edit_customer.id !== null
//                     ? format(new Date(creation_date.value), "yyyy-MM-dd")
//                     : creation_date.value
//                 }
//                 type="date"
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!creation_date.valid && creation_date.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addCreationDate(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Edited On"
//                 fullWidth
//                 type="date"
//                 value={
//                   state.actions.edit_customer.id !== null
//                     ? format(new Date(edited_on.value), "yyyy-MM-dd")
//                     : edited_on.value
//                 }
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!edited_on.valid && edited_on.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addEditedOn(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Edited By"
//                 fullWidth
//                 value={edited_by.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!edited_by.valid && edited_by.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addEditedBy(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Salutation"
//                 fullWidth
//                 value={salutation.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!salutation.valid && salutation.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addSalutation(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Phone"
//                 fullWidth
//                 value={phone.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!phone.valid && phone.entered}
//                 onChange={({ target: { value } }) => this.props.addPhone(value)}
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Email"
//                 fullWidth
//                 value={email.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!email.valid && email.entered}
//                 onChange={({ target: { value } }) => this.props.addEmail(value)}
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Website"
//                 fullWidth
//                 value={website.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!website.valid && website.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addWebsite(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Address"
//                 fullWidth
//                 value={address.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!address.valid && address.entered}
//                 onChange={({ target: { value } }) => this.props.addAddr(value)}
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Address City"
//                 fullWidth
//                 value={address_city.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!address_city.valid && address_city.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addAddrCity(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Address Postcode"
//                 fullWidth
//                 value={address_postcode.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!address_postcode.valid && address_postcode.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addAddrPCode(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Address Country Code"
//                 fullWidth
//                 value={address_countrycode.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={
//                   !address_countrycode.valid && address_countrycode.entered
//                 }
//                 onChange={({ target: { value } }) =>
//                   this.props.addAddrCcode(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Postbox Name"
//                 fullWidth
//                 value={postbox_name.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!postbox_name.valid && postbox_name.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addPBoxName(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Postbox Postcode"
//                 fullWidth
//                 value={postbox_postcode.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!postbox_postcode.valid && postbox_postcode.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addPBoxPCode(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Postbox City"
//                 fullWidth
//                 value={postbox_city.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!postbox_city.valid && postbox_city.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addPBoxCity(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Postbox Countrycode"
//                 fullWidth
//                 value={postbox_countrycode.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={
//                   !postbox_countrycode.valid && postbox_countrycode.entered
//                 }
//                 onChange={({ target: { value } }) =>
//                   this.props.addPBoxCCode(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Bank"
//                 fullWidth
//                 value={bank.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!bank.valid && bank.entered}
//                 onChange={({ target: { value } }) => this.props.addBank(value)}
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Bank Account"
//                 fullWidth
//                 value={bank_account.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!bank_account.valid && bank_account.entered}
//                 onChange={({ target: { value } }) => {
//                   this.props.addBankAccount(value);
//                 }}
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Bank User"
//                 fullWidth
//                 value={bank_user.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!bank_user.valid && bank_user.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addBankUser(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Bank IBAN"
//                 fullWidth
//                 value={bank_iban.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!bank_iban.valid && bank_iban.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addBankIBAN(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Bank BIC"
//                 fullWidth
//                 value={bank_bic.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!bank_bic.valid && bank_bic.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addBankBIC(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Bank Country Code"
//                 fullWidth
//                 value={bank_countrycode.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!bank_countrycode.valid && bank_countrycode.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addBankCCode(value)
//                 }
//               />
//               <TextField
//
//                 margin="dense"
//                 variant="outlined"
//                 label="Bank Currency"
//                 fullWidth
//                 value={bank_currency.value}
//                 disabled={
//                   state.actions.create_customer.loading ||
//                   state.actions.edit_customer.loading
//                 }
//                 error={!bank_currency.valid && bank_currency.entered}
//                 onChange={({ target: { value } }) =>
//                   this.props.addBankCurrency(value)
//                 }
//               />
//               {state.actions.edit_customer.id !== null ? (
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={state.actions.edit_customer.checked}
//                       onChange={(e) => this.props.checkPrompt()}
//                       color="primary"
//                     />
//                   }
//                   label="I confirm to edit this customer"
//                 />
//               ) : null}
//               {state.actions.edit_customer.loading ||
//               state.actions.create_customer.loading ? (
//                 <LinearProgress />
//               ) : null}
//             </DialogContent>
//             <DialogActions>
//               <button
//                 className="edit-btn"
//                 color="secondary"
//                 onClick={(e) => this.props.closeCreateCustomer()}
//               >
//                 Close
//               </button>
//               <button
//                 color="primary"
//                 className={disabled ? "edit-btn custom-disabled" : "edit-btn"}
//                 disabled={disabled}
//                 onClick={(e) => {
//                   state.actions.edit_customer.id !== null
//                     ? this.props.editCustomer()
//                     : this.props.createCustomer();
//                 }}
//               >
//                 {state.actions.edit_customer.id !== null ? "Edit" : "Create"}
//               </button>
//             </DialogActions>
//           </Dialog>

//           {/* VIEW CUSTOMER */}
//           <Dialog
//             open={view_customer_open}
//             PaperProps={{
//               style: {
//                 borderRadius: 20,
//                 padding: "20px 10px",
//                 width: "50%",
//               },
//             }}
//           >
//             <DialogTitle style={{ fontWeight: "bold" }}>
//               {opened_customer.customer_name}
//             </DialogTitle>
//             <DialogContent>
//               <Table>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Debitor Number
//                     </TableCell>
//                     <TableCell>{opened_customer.debitor_number}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Creation Date
//                     </TableCell>
//                     <TableCell>{opened_customer.creation_date}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Edited On
//                     </TableCell>
//                     <TableCell>{opened_customer.edited_on}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Edited By
//                     </TableCell>
//                     <TableCell>{opened_customer.edited_by}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Salutation
//                     </TableCell>
//                     <TableCell>{opened_customer.salutation}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>Phone</TableCell>
//                     <TableCell>{opened_customer.phone}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
//                     <TableCell>{opened_customer.email}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Website
//                     </TableCell>
//                     <TableCell>{opened_customer.website}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Address
//                     </TableCell>
//                     <TableCell>{opened_customer.address}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Address City
//                     </TableCell>
//                     <TableCell>{opened_customer.address_city}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Address Postcode
//                     </TableCell>
//                     <TableCell>{opened_customer.address_postcode}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Address Country Code
//                     </TableCell>
//                     <TableCell>{opened_customer.address_countrycode}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Postbox Name
//                     </TableCell>
//                     <TableCell>{opened_customer.postbox_name}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Postbox Postcode
//                     </TableCell>
//                     <TableCell>{opened_customer.postbox_postcode}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Postbox City
//                     </TableCell>
//                     <TableCell>{opened_customer.postbox_city}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Postbox Country Code
//                     </TableCell>
//                     <TableCell>{opened_customer.postbox_countrycode}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>Bank</TableCell>
//                     <TableCell>{opened_customer.bank}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Bank Code
//                     </TableCell>
//                     <TableCell>{opened_customer.bank_code}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Bank Account
//                     </TableCell>
//                     <TableCell>{opened_customer.bank_account}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Bank User
//                     </TableCell>
//                     <TableCell>{opened_customer.bank_user}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Bank IBAN
//                     </TableCell>
//                     <TableCell>{opened_customer.bank_iban}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Bank BIC
//                     </TableCell>
//                     <TableCell>{opened_customer.bank_bic}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Bank Country Code
//                     </TableCell>
//                     <TableCell>{opened_customer.bank_countrycode}</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell style={{ fontWeight: "bold" }}>
//                       Bank Currency
//                     </TableCell>
//                     <TableCell>{opened_customer.bank_currency}</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </DialogContent>
//             <DialogActions>
//               <button
//                 className="edit-btn"
//                 color="secondary"
//                 onClick={(e) => this.props.closeViewCustomer()}
//               >
//                 Close
//               </button>
//             </DialogActions>
//           </Dialog>
//   <div
//     style={{
//       padding: "0px 90px",
//     }}
//   >
//     <span className="header">Customer</span>
//     <span className="header-buttons">
//       <button
//         className="button"
//         variant="contained"
//         onClick={(e) => this.props.openCreateCustomer()}
//       >
//         Create Customer
//       </button>
//     </span>
//   </div>
//   <div
//     style={{
//       marginTop: 30,
//       padding: "0 80",
//     }}
//   >
//     <CustomerList state={state} actions={actions} />
//   </div>
// </div>
//       </>
//     );
//   }
// }

export default Customers;
