import React, { Fragment, Component, useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ComplainList } from "./complainList";
import { CMap } from "./CMap";
import { CategoryAnalysis } from "./categoryAnalysis";
import { SentimentAnalysis } from "./sentiment";
import AuthenticatedNavbar from "./../authenticatedNavbar/AuthenticatedNavbar";
import "./../global.css";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { backendHost } from "../../config";
import { useForm } from "react-hook-form";
import ReactHookFormSelect from "../ReactHookFormSelect";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const Complains = () => {
  const queryClient = useQueryClient();
  const [activeView, setActiveView] = useState("list");
  const [viewOrChange, setViewOrChange] = useState(null);
  const [createModal, setCreateModal] = useState(false);

  const changeStatus = useMutation({
    mutationFn: async (data) => {
      return await axios.post(
        `${backendHost}/api/complains/v1/change-status`,
        data
      );
    },
  });

  const createCategory = useMutation({
    mutationFn: async (data) => {
      return await axios.post(
        `${backendHost}/api/complains/v1/create-category`,
        data
      );
    },
  });

  const form = useForm({
    defaultValues: {
      status: viewOrChange?.data?.status_id,
    },
  });

  const createForm = useForm({
    resolver: zodResolver(
      z.object({
        debitorName: z.string().min(1),
      })
    ),
  });

  const onSubmitChange = (data) => {
    changeStatus.mutate(
      {
        status_id: data?.status,
        id: viewOrChange?.data?.orig_id,
      },
      {
        onSettled: () => {
          queryClient.refetchQueries(["complains"]);
          setViewOrChange(null);
        },
      }
    );
  };

  const onCreateCategory = (data) => {
    createCategory.mutate(
      {
        category: data?.debitorName,
      },
      {
        onSettled: () => {
          createForm.reset();
          setCreateModal(false);
          queryClient.refetchQueries(["complains"]);
        },
      }
    );
  };

  useEffect(() => {
    form.reset({
      status: viewOrChange?.data?.status_id,
    });
  }, [viewOrChange?.show]);

  return (
    <>
      <AuthenticatedNavbar />

      <div className="container mx-auto space-y-4 md:pt-10 pb-[70px] pt-4 px-2">
        {/* CREATE COMPLAIN */}
        <Dialog
          open={createModal}
          PaperProps={{
            style: {
              borderRadius: 20,
              margin: 0,
            },
          }}
        >
          <form
            onSubmit={createForm.handleSubmit(onCreateCategory)}
            className="py-4"
          >
            <DialogTitle style={{ fontWeight: "bold" }}>
              Create Complain Category
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                variant="outlined"
                label="Debitor Name"
                fullWidth
                {...createForm.register("debitorName")}
              />
            </DialogContent>
            <DialogActions>
              <button
                className="edit-btn"
                onClick={() => {
                  setCreateModal(false);
                }}
                type="button"
              >
                Close
              </button>
              <button
                type="submit"
                color="primary"
                disabled={createForm?.formState?.errors?.debitorName}
                className={
                  createForm?.formState?.errors?.debitorName
                    ? "edit-btn custom-disabled"
                    : "edit-btn"
                }
              >
                Create
              </button>
            </DialogActions>
          </form>
        </Dialog>
        {/* View/Change Modal */}
        <Dialog
          open={!!viewOrChange?.show}
          PaperProps={{
            style: {
              borderRadius: 20,
              margin: 0,
            },
          }}
        >
          <form onSubmit={form.handleSubmit(onSubmitChange)} className="py-4">
            <DialogTitle style={{ fontWeight: "bold" }}>
              Change Status
            </DialogTitle>
            <DialogContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Complain
                    </TableCell>
                    <TableCell>{viewOrChange?.data?.description}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell>
                      <ReactHookFormSelect
                        label="Status"
                        variant="outlined"
                        fullWidth
                        control={form.control}
                        name="status"
                        margin="dense"
                      >
                        <MenuItem value={1}>Unresolved</MenuItem>
                        <MenuItem value={2}>In-process</MenuItem>
                        <MenuItem value={3}>Resolved</MenuItem>
                      </ReactHookFormSelect>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <button
                type="button"
                className="edit-btn"
                color="secondary"
                onClick={() => {
                  setViewOrChange(null);
                }}
              >
                Close
              </button>
              <button type="submit" className="edit-btn px-2" color="primary">
                Change Status
              </button>
            </DialogActions>
          </form>
        </Dialog>

        <div className="flex justify-between items-center">
          <span className="font-semibold text-xl md:text-2xl">Complains</span>
          <span className="header-buttons">
            <button
              className="button"
              variant="contained"
              onClick={() => {
                setCreateModal(true);
              }}
            >
              Create Category
            </button>
          </span>
        </div>

        <div className="flex justify-center">
          <ButtonGroup color="primary" aria-label="text primary button group">
            <button
              className="edit-btn md:w-[150px] w-[100px] md:text-[14px] text-[10px]"
              style={{
                background: activeView === "list" ? "#ec712e" : "#fff",
                color: activeView === "list" ? "#fff" : "#ec712e",
              }}
              color={activeView === "list" ? "secondary" : null}
              onClick={(e) => setActiveView("list")}
            >
              Complain List
            </button>
            <button
              className="edit-btn md:w-[150px] w-[100px] md:text-[14px] text-[10px]"
              style={{
                background: activeView === "category" ? "#ec712e" : "#fff",
                color: activeView === "category" ? "#fff" : "#ec712e",
              }}
              color={activeView === "category" ? "secondary" : null}
              onClick={(e) => setActiveView("category")}
            >
              Category Analysis
            </button>
            <button
              className="edit-btn md:w-[150px] w-[100px] md:text-[14px] text-[10px]"
              style={{
                background: activeView === "map" ? "#ec712e" : "#fff",
                color: activeView === "map" ? "#fff" : "#ec712e",
              }}
              color={activeView === "map" ? "secondary" : null}
              onClick={(e) => setActiveView("map")}
            >
              Complain Map
            </button>
          </ButtonGroup>
        </div>

        <div>
          {activeView === "list" ? (
            <ComplainList setViewOrChange={setViewOrChange} />
          ) : activeView === "category" ? (
            <CategoryAnalysis />
          ) : (
            <CMap />
          )}
        </div>
      </div>
    </>
  );
};

export default Complains;
