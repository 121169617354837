import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { backendHost } from "../../config";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TableContainer } from "@material-ui/core";
import { Paper } from "@material-ui/core";

export const ComplainList = ({ setViewOrChange }) => {
  const complains = useQuery({
    queryKey: "complains",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/complains/v1/complains`);
      return res?.data?.data?.complains;
    },
  });

  const deleteComplain = useMutation({
    mutationFn: async (data) => {
      return await axios.post(`${backendHost}/api/complains/v1/delete`, data);
    },
  });

  return (
    <>
      {complains.isLoading || deleteComplain.isLoading ? (
        <LinearProgress />
      ) : null}
      <Paper className="w-full overflow-hidden">
        <TableContainer className="max-h-[580px]">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  People Affected
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  Facing Since
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  View/Change
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {complains.data?.map((t) => {
                return (
                  <TableRow key={t.id}>
                    <TableCell
                      style={{
                        fontSize: 16,
                      }}
                    >
                      {t.title}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 16,
                      }}
                    >
                      {t.people}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 16,
                      }}
                    >
                      {t.days}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 16,
                      }}
                    >
                      {t.category}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 16,
                      }}
                    >
                      {t.status}
                    </TableCell>
                    <TableCell>
                      <button
                        className="edit-btn view-btn"
                        style={{
                          width: "8.25rem",
                        }}
                        onClick={(e) =>
                          setViewOrChange({
                            show: true,
                            data: t,
                          })
                        }
                      >
                        View/Change
                      </button>
                    </TableCell>
                    <TableCell>
                      <button
                        className="edit-btn delete-btn"
                        disabled={deleteComplain.isLoading}
                        onClick={() => {
                          deleteComplain.mutate(
                            {
                              id: t.id,
                            },
                            {
                              onSuccess: () => {
                                complains.refetch();
                              },
                            }
                          );
                        }}
                      >
                        Remove
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
