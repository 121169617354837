import React from "react";
import "./loginForm.css";
import { Link } from "react-router-dom";

export default function LoginNavBar() {
  return (
    <nav className="bg-[#009fe3]">
      <div className="container mx-auto">
        <Link to="/">
          <img src="/images/logo.png" alt="" className="size-20" />
        </Link>
      </div>
    </nav>
  );
}
