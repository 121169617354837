import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import format from "date-fns/format";
import AuthenticatedNavbar from "./../authenticatedNavbar/AuthenticatedNavbar";
import "./../global.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { backendHost } from "../../config";
import { useForm } from "react-hook-form";
import ReactHookFormSelect from "../ReactHookFormSelect";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TableContainer } from "@material-ui/core";
import { Paper } from "@material-ui/core";

const woSchema = z.object({
  id: z.any(),
  start_date: z.string().min(1),
  house_number: z.number().nullish(),
  street: z.string().nullish(),
  city: z.string().min(1),
  state: z.string().nullish(),
  country: z.string().min(1),
  zip: z.number().positive(),
  project: z.number().positive(),
  task: z.number().positive(),
  customer: z.number().positive(),
  created_by: z.number().positive(),
});

const WO = () => {
  const queryClient = useQueryClient();
  const [openedProject, setOpenedProject] = useState(null);
  const [createOrEdit, setCreateOrEdit] = useState(null);

  const workingOrders = useQuery({
    queryKey: "wo",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/wo`);
      return res?.data?.data?.wo;
    },
  });

  const tasks = useQuery({
    queryKey: "tasks",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/tasks/v1/tasks`);
      return res?.data?.data?.tasks;
    },
  });

  const projects = useQuery({
    queryKey: "projects",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/projects/v1/projects`);
      return res?.data?.data?.projects;
    },
  });

  const customers = useQuery({
    queryKey: "customers",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/customers/v1/customers`);
      return res?.data?.data?.customers;
    },
  });

  const employees = useQuery({
    queryKey: "employees",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/employees/v1/employees`);
      return res?.data?.data?.employees;
    },
  });

  const createWo = useMutation({
    mutationFn: async (data) => {
      return await axios.post(`${backendHost}/api/wo/v1/create-app`, data);
    },
  });

  const updateWo = useMutation({
    mutationFn: async (data) => {
      return await axios.post(
        `${backendHost}/api/wo/v1/edit-wo/${data?.id}`,
        data
      );
    },
  });

  const defaultFormValues = {
    start_date: createOrEdit?.data?.start_date
      ? format(new Date(createOrEdit?.data?.start_date), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    house_number: createOrEdit?.data?.house_number ?? null,
    street: createOrEdit?.data?.street ?? "",
    city: createOrEdit?.data?.city ?? "",
    state: createOrEdit?.data?.state ?? "",
    country: createOrEdit?.data?.country ?? "",
    zip: createOrEdit?.data?.zip ?? null,
    project: createOrEdit?.data?.project ?? null,
    task: createOrEdit?.data?.task ?? null,
    customer: createOrEdit?.data?.customer ?? null,
    created_by: createOrEdit?.data?.created_by ?? null,
    id: createOrEdit?.data?.id ?? null,
  };

  const form = useForm({
    resolver: zodResolver(woSchema),
    defaultValues: defaultFormValues,
  });
  const onSubmit = async (data) => {
    if (createOrEdit?.mode === "create") {
      createWo.mutate(
        {
          start_date: data?.start_date,
          house_number: data?.house_number,
          street: data?.street,
          city: data?.city,
          state: data?.state,
          country: data?.country,
          zip: data?.zip,
          project: data?.project,
          task: data?.task,
          customer: data?.customer,
          id: data?.created_by,
        },
        {
          onSettled: () => {
            queryClient.refetchQueries(["wo"]);
            setCreateOrEdit(null);
          },
        }
      );
    } else {
      updateWo.mutate(
        {
          id: data?.id,
          start_date: data?.start_date,
          house_number: data?.house_number,
          street: data?.street,
          city: data?.city,
          state: data?.state,
          country: data?.country,
          zip: data?.zip,
          project: data?.project,
          task: data?.task,
          customer: data?.customer,
          created_by: data?.created_by,
        },
        {
          onSettled: () => {
            queryClient.refetchQueries(["wo"]);
            setCreateOrEdit(null);
          },
        }
      );
    }
  };

  useEffect(() => {
    form.reset(defaultFormValues);
  }, [createOrEdit?.show]);

  return (
    <>
      <AuthenticatedNavbar />
      <div className="container mx-auto space-y-4 md:pt-10 pb-[70px] pt-4 px-2">
        {/* View Modal */}
        <Dialog
          open={!!openedProject?.show}
          PaperProps={{
            style: {
              borderRadius: 20,
              margin: 0,
            },
          }}
        >
          <DialogTitle style={{ fontWeight: "bold" }}>
            {openedProject?.wo?.id}
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Id</TableCell>
                  <TableCell>{openedProject?.wo?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Start Date
                  </TableCell>
                  <TableCell>{openedProject?.wo?.start_date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    House Number
                  </TableCell>
                  <TableCell>{openedProject?.wo?.house_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Street</TableCell>
                  <TableCell>{openedProject?.wo?.street}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
                  <TableCell>{openedProject?.wo?.city}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>State</TableCell>
                  <TableCell>{openedProject?.wo?.state}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Country</TableCell>
                  <TableCell>{openedProject?.wo?.country}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Zip</TableCell>
                  <TableCell>{openedProject?.wo?.zip}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Task</TableCell>
                  <TableCell>{openedProject?.wo?.task_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Project</TableCell>
                  <TableCell>{openedProject?.wo?.project_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Customer</TableCell>
                  <TableCell>{openedProject?.wo?.customer_name}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <button
              className="edit-btn"
              color="secondary"
              onClick={() => {
                setOpenedProject(null);
              }}
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
        {/* Create/Update Modal */}
        <Dialog
          open={!!createOrEdit?.show}
          PaperProps={{
            style: {
              borderRadius: 20,
              margin: 0,
            },
          }}
        >
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogTitle>
              {createOrEdit?.mode === "create" ? "Create" : "Edit"} Project
            </DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                variant="outlined"
                type="date"
                label="Start Date"
                fullWidth
                {...form.register("start_date")}
                error={form.formState?.errors?.start_date}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="House Number"
                fullWidth
                {...form.register("house_number", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
                error={form.formState?.errors?.house_number}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Street"
                fullWidth
                {...form.register("street")}
                error={form.formState?.errors?.street}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="City"
                fullWidth
                {...form.register("city")}
                error={form.formState?.errors?.city}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="State"
                fullWidth
                {...form.register("state")}
                error={form.formState?.errors?.state}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Country"
                fullWidth
                {...form.register("country")}
                error={form.formState?.errors?.country}
              />

              <TextField
                margin="dense"
                variant="outlined"
                label="Zip"
                fullWidth
                {...form.register("zip", {
                  setValueAs: (v) => (v === "" ? 0 : parseInt(v, 10)),
                })}
                error={form.formState?.errors?.zip}
              />

              <ReactHookFormSelect
                label="Task"
                variant="outlined"
                fullWidth
                control={form.control}
                name="task"
                margin="dense"
                error={form.formState?.errors?.task}
              >
                {tasks?.data?.map((t) => (
                  <MenuItem key={t?.id} value={t?.id}>
                    {t?.task}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>

              <ReactHookFormSelect
                label="Project"
                variant="outlined"
                fullWidth
                control={form.control}
                name="project"
                margin="dense"
                error={form.formState?.errors?.project}
              >
                {projects?.data?.map((p) => (
                  <MenuItem key={p?.id} value={p?.id}>
                    {p?.name}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>

              <ReactHookFormSelect
                label="Customer"
                variant="outlined"
                fullWidth
                control={form.control}
                name="customer"
                margin="dense"
                error={form.formState?.errors?.customer}
              >
                {customers?.data?.map((c) => (
                  <MenuItem key={c?.id} value={c?.id}>
                    {c?.name}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>

              <ReactHookFormSelect
                label="Created By"
                variant="outlined"
                fullWidth
                control={form.control}
                name="created_by"
                margin="dense"
                error={form.formState?.errors?.created_by}
              >
                {employees?.data?.map((e) => (
                  <MenuItem key={e?.id} value={e?.id}>
                    {e?.first_name} {e?.last_name}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </DialogContent>
            <DialogActions>
              <button
                className="edit-btn"
                onClick={() => {
                  setCreateOrEdit(null);
                }}
                type="button"
              >
                Close
              </button>
              <button
                className="edit-btn"
                type="submit"
                // disabled={createProject.isLoading}
              >
                {createOrEdit?.mode === "create" ? "Create" : "Edit"}
              </button>
            </DialogActions>
          </form>
        </Dialog>
        <div className="flex justify-between items-center">
          <span className="font-semibold text-xl md:text-2xl">
            Working Orders
          </span>
          <span className="header-buttons">
            <button
              className="button"
              onClick={() => {
                setCreateOrEdit({
                  show: true,
                  mode: "create",
                });
              }}
            >
              Create WO
            </button>
          </span>
        </div>

        <div>
          <Paper className="w-full overflow-hidden">
            <TableContainer className="max-h-[580px]">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Start Date
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      City
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Country
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Project
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Task
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Customer
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      View
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Edit
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Remove
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workingOrders?.data?.map((wo, index) => (
                    <WoItem
                      wo={wo}
                      setOpenedProject={setOpenedProject}
                      key={index}
                      setCreateOrEdit={setCreateOrEdit}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </>
  );
};

const WoItem = ({ wo, setOpenedProject, setCreateOrEdit }) => {
  const queryClient = useQueryClient();

  const removeWo = useMutation({
    mutationFn: async ({ wo }) => {
      return await axios.get(`${backendHost}/api/wo/v1/remove/${wo?.id}`);
    },
  });

  return (
    <>
      <TableRow>
        <TableCell
          style={{
            fontSize: 16,
          }}
        >
          {wo?.start_date}
        </TableCell>
        <TableCell
          style={{
            fontSize: 16,
          }}
        >
          {wo?.city}
        </TableCell>
        <TableCell
          style={{
            fontSize: 16,
          }}
        >
          {wo?.country}
        </TableCell>
        <TableCell
          style={{
            fontSize: 16,
          }}
        >
          {wo?.project_name}
        </TableCell>
        <TableCell
          style={{
            fontSize: 16,
          }}
        >
          {wo?.task_name}
        </TableCell>
        <TableCell
          style={{
            fontSize: 16,
          }}
        >
          {wo?.customer_name}
        </TableCell>
        <TableCell align="right">
          <button
            className="edit-btn view-btn"
            variant="contained"
            onClick={() => {
              setOpenedProject({
                show: true,
                mode: "view",
                wo: wo,
              });
            }}
          >
            View
          </button>
        </TableCell>
        <TableCell
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="edit-btn"
            onClick={() => {
              setCreateOrEdit({
                show: true,
                mode: "edit",
                data: {
                  ...wo,
                  project: wo?.project_id,
                  customer: wo?.customer_id,
                  task: wo?.task_id,
                  created_by: wo?.created_by,
                  id: wo?.id,
                },
              });
            }}
          >
            Edit
          </button>
        </TableCell>
        <TableCell align="right">
          <button
            className="edit-btn delete-btn"
            disabled={removeWo.isLoading}
            onClick={() => {
              removeWo.mutate(
                {
                  wo: wo,
                },
                {
                  onSettled: () => {
                    queryClient.refetchQueries(["wo"]);
                  },
                }
              );
            }}
          >
            Remove
          </button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WO;
