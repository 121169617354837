import React, { useState } from "react";
import { Map, Marker } from "pigeon-maps";
import { useQuery } from "react-query";
import axios from "axios";
import { backendHost } from "../../config";
import LinearProgress from "@material-ui/core/LinearProgress";

export const CMap = () => {
  const [city, setCity] = useState("No City Selected");
  const [zoom, setZoom] = useState(3);

  // const map = useQuery({
  //   queryKey: "map",
  //   queryFn: async () => {
  //     const res = await axios.get(`${backendHost}/api/complains/v1/map`);
  //     return res?.data?.data?.complain_cities;
  //   },
  // });

  const complains = useQuery({
    queryKey: "complains",
    queryFn: async () => {
      const res = await axios.get(`${backendHost}/api/complains/v1/complains`);
      return res?.data?.data?.complains;
    },
  });

  const cities = complains?.data?.filter(
    (c) => c.lat && c.lng && c.status != "resolved"
  );

  if (complains.isLoading) return <LinearProgress />;

  return (
    <>
      <center>
        {/* <h3>City: {city}</h3> */}
        <br />
        <Map
          center={[4.051056, 9.767869]}
          zoom={zoom}
          width={600}
          height={400}
          metaWheelZoom={true}
        >
          {cities?.map((c) => (
            <Marker
              key={c.id}
              payload={1}
              anchor={[c.lat, c.lng]}
              onClick={({ event, anchor, payload }) => {
                window.open(
                  `http://maps.google.com/maps?q=loc:${c.lat},${c.lng}`,
                  "_blank"
                );
              }}
            />
          ))}
          {/* {map.data?.map((m) => {
            var loc = m.city + ", " + m.country;
            var lat = Number(m.lat);
            var lon = Number(m.long);
            return (
              <Marker
                anchor={[lat, lon]}
                payload={1}
                onClick={({ event, anchor, payload }) => setCity(loc)}
                key={m.id}
              />
            );
          })} */}
        </Map>
        <br />
        <button onClick={(e) => setZoom(Math.min(zoom + 1, 6))}>Zoom In</button>
        <button onClick={(e) => setZoom(Math.max(zoom - 1, 1))}>
          Zoom Out
        </button>
      </center>
    </>
  );
};
