import React from "react";
import { logout } from "../../lib/api";
import "./authenticatedNavbar.css";
import { Home } from "lucide-react";
import { Link } from "react-router-dom";

export default function AuthenticatedNavbar() {
  return (
    <nav className="bg-[#009fe3]">
      <div className="flex justify-between items-center container mx-auto">
        <div>
          <Link to="/">
            <img src="/images/logo.png" alt="loho" width="80" />
          </Link>
        </div>
        <div className="flex items-center gap-5">
          <div>
            <Link to="/">
              <Home color="#fff" />
            </Link>
          </div>
          <div>
            <a
              onClick={logout}
              className="button"
              style={{
                fontSize: "0.8rem",
                width: "8rem",
                height: "2.2rem",
              }}
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
